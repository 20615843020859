import { createSlice } from '@reduxjs/toolkit';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import { headerParams } from 'src/utils/headers';
import axios from '../../../utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    loading: true,
    error: null,
    assignedToList: null,
}

const slice = createSlice({
    name: 'assignedToList',
    initialState,
    reducers: {
        startLoading(state) {
            state.loading = true;
            state.assignedToList = null;
            state.totalpage = null;
        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        getAssignedToList(state, action) {
            state.loading = false;
            state.error = null;
            state.assignedToList = action.payload?.data;
           
        },
        resetStateFetch(state) {
            state.loading = true;
            state.assignedToList = null;
            state.error = null;
        }
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { getAssignedToList } = slice.actions;

export function GetAssignedToList(token, orgId) {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateFetch());
        dispatch(slice.actions.startLoading());
        try {
              const response = await axios.get(`${DomainURL.BaseUrlVersion2}user/assignedToList`, {
                    headers: headerParams(token),
                });
            
            dispatch(slice.actions.getAssignedToList(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function resetAssignedToList() {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateFetch());
    }

}
