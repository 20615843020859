import { createSlice } from '@reduxjs/toolkit';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import { headerParams } from 'src/utils/headers';
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    loading: true,
    error: null,
    exclusiveJobLists: null,
}

const slice = createSlice({
    name: 'exclusiveJobLists',
    initialState,
    reducers: {
        startLoading(state) {
            state.loading = true;

        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        getExclusiveJobLists(state, action) {
            state.loading = false;
            state.error = null;
            state.exclusiveJobLists = action.payload?.data;

        },
        resetStateFetch(state) {
            state.loading = false;
            state.exclusiveJobLists = null;
            state.error = null;
        }
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { getExclusiveJobLists } = slice.actions;

export function GetExclusiveJobLists(token, pageNumber, status) {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateFetch());
        dispatch(slice.actions.startLoading());
        try {
            let response = null
            if (status) {
                response = await axios.get(`${DomainURL.BaseUrlVersion2}pipeline/jobs/applicant-metrics?status=${status}&pageNumber=${pageNumber}&pageSize=10`, { headers: headerParams(token) });

            } else {
                response = await axios.get(`${DomainURL.BaseUrlVersion2}pipeline/jobs/applicant-metrics?pageNumber=${pageNumber}&pageSize=10`, { headers: headerParams(token) });

            }


            dispatch(slice.actions.getExclusiveJobLists(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function resetOrgExclusiveJobList() {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateFetch());
    }
}

