import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
import SimpleLayout from 'src/layouts/simple';
import CompactLayout from 'src/layouts/compact';
// components
import { SplashScreen } from 'src/components/loading-screen';

const Page404 = lazy(() => import('src/pages/404'));

export const errorRoutes = [

    {
        element: (
            <CompactLayout>
                <Suspense fallback={<SplashScreen />}>
                    <Outlet />
                </Suspense>
            </CompactLayout>
        ),
        children: [
            { path: '404', element: <Page404 /> },
        ],
    },
];