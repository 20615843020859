import PropTypes from 'prop-types';
import { useEffect } from 'react';
// redux-store
import { useSelector } from 'src/redux/store';
import { useRouter, usePathname } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';

export default function OnboardingGuard({ roles, children }) {
    const { reqCollection } = useSelector(state => state.profileStatus)
    const { userPreview } = useSelector(state => state.userInfo);
    const currentRole = userPreview?.systemRole;
    const router = useRouter();
    const pathname = usePathname();

    const onboardBlocks = () => {

        if (typeof userPreview?.isMobilePhoneVerified === "boolean" &&
            !userPreview?.isMobilePhoneVerified) {
            // eslint-disable-next-line no-restricted-globals
            router.push(paths.onboarding.employer.userAccount)
        }

    }

    useEffect(() => {
        let isCalled = false
        if (!isCalled && roles.includes(currentRole)) {
            onboardBlocks()
        }
        return () => {
            isCalled = true
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userPreview])

    return (<>{children}</>)
}
OnboardingGuard.propTypes = {
    roles: PropTypes.string,
    children: PropTypes.node,
};