import { m } from 'framer-motion';
import { useState, useCallback } from 'react';
// @mui
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// _mock
import { _notifications } from 'src/_mock';
// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { varHover } from 'src/components/animate';
//
import { useSelector } from 'src/redux/store';
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
import NotificationItem from './notification-item';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const drawer = useBoolean();

  const smUp = useResponsive('up', 'sm');

  const [currentTab, setCurrentTab] = useState('all');

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  const [notifications, setNotifications] = useState(_notifications);

  const { alert,totalRecord } = useSelector(state => state.notificationAlert)

  // const totalUnRead = alert && alert.filter((item) => item.status === "NEW").length;
  const totalUnRead = totalRecord;

  const handleMarkAllAsRead = () => {
    setNotifications(
      alert.map((notification) => ({
        ...notification,
        status: false,
      }))
    );
  };

  const renderHead = (
    <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Notifications
      </Typography>

      {/* {!!totalUnRead && (
        <Tooltip title="Mark all as read">
          <IconButton color="primary" onClick={handleMarkAllAsRead}>
            <Iconify icon="eva:done-all-fill" />
          </IconButton>
        </Tooltip>
      )} */}

      {!smUp && (
        <IconButton onClick={drawer.onFalse}>
          <Iconify icon="mingcute:close-line" />
        </IconButton>
      )}
    </Stack>
  );

  // const renderTabs = (
  //   <Tabs value={currentTab} onChange={handleChangeTab}>
  //     {TABS.map((tab) => (
  //       <Tab
  //         key={tab.value}
  //         iconPosition="end"
  //         value={tab.value}
  //         label={tab.label}
  //         icon={
  //           <Label
  //             variant={((tab.value === 'all' || tab.value === currentTab) && 'filled') || 'soft'}
  //             color={
  //               (tab.value === 'unread' && 'info') ||
  //               (tab.value === 'archived' && 'success') ||
  //               'default'
  //             }
  //           >
  //             {tab.value === "all" && alert.filter((item) => item.status === "NEW").length}
  //             {tab.value === "unread" && alert.filter((item) => item.status === "NEW").length}
  //             {tab.value === "archived" && alert.filter((item) => item.status === "archived").length}
  //           </Label>
  //         }
  //         sx={{
  //           '&:not(:last-of-type)': {
  //             mr: 3,
  //           },
  //         }}
  //       />
  //     ))}
  //   </Tabs>
  // );

  const renderList = (
    <Scrollbar>
      <List disablePadding>
        {alert && alert.map((notification) => (
          <NotificationItem key={notification.id} notification={notification} />
        ))}
      </List>
    </Scrollbar>
  );

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={drawer.value ? 'primary' : 'default'}
        onClick={drawer.onTrue}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="solar:bell-bing-bold-duotone" width={24} />
        </Badge>
      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: 420 },
        }}
      >
        {renderHead}

        <Divider />

        {/* <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ pl: 2.5, pr: 1 }}
        >
          {renderTabs} 
          <IconButton onClick={handleMarkAllAsRead}>
            <Iconify icon="solar:settings-bold-duotone" />
          </IconButton>
        </Stack> */}

        <Divider />

        {renderList}

        <Box sx={{ p: 1 }}>
          <Button fullWidth size="large" component={RouterLink} href={paths.dashboard.common.notications.view}>
            View All
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
