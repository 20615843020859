import { createSlice } from '@reduxjs/toolkit';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import { headerParams } from 'src/utils/headers';
import axios from '../../../../../utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    loadingInfo: false,
    error: null,
    searchUserIndiData: null,
}

const slice = createSlice({
    name: 'OrgIndiUserSearch',
    initialState,
    reducers: {
        startLoading(state) {
            state.loadingInfo = true;

        },
        hasError(state, action) {
            state.loadingInfo = false;
            state.error = action.payload;
        },
        searchOrgIndiUser(state, action) {
            state.loadingInfo = false;
            state.error = null;
            state.searchUserIndiData = action.payload?.data;

        },
        resetStateSearch(state) {
            state.loadingInfo = false;
            state.searchUserIndiData = null;
            state.error = null;
        }
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { searchOrgIndiUser } = slice.actions;

export function GetorgIndiUserSearch(token, email, systemRole) {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateSearch());
        dispatch(slice.actions.startLoading());
        let response = null;
        try {
            if (systemRole) {
                response = await axios.get(`${DomainURL.BaseUrlVersion2}user/list?systemRole=${systemRole}&email=${email}`, {
                    headers: headerParams(token),
                });
            } else {
                response = await axios.get(`${DomainURL.BaseUrlVersion2}user/list?email=${email}`, {
                    headers: headerParams(token),
                });
            }

            dispatch(slice.actions.searchOrgIndiUser(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function resetOrgUserSearchState() {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateSearch());
    }
}
