import { createSlice } from '@reduxjs/toolkit';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import { headerParams } from 'src/utils/headers';
import axios from '../../../../../utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    loadingInfo: false,
    error: null,
    employmentVerificationUser: null,
}

const slice = createSlice({
    name: 'OrgEmploymentVerificationSearch',
    initialState,
    reducers: {
        startLoading(state) {
            state.loadingInfo = true;

        },
        hasError(state, action) {
            state.loadingInfo = false;
            state.error = action.payload;
        },
        fetchOrgEmploymentVerificationUser(state, action) {
            state.loadingInfo = false;
            state.error = null;
            state.employmentVerificationUser = action.payload?.data;

        },
        resetStateEmploymentVerificationUser(state) {
            state.loadingInfo = false;
            state.employmentVerificationUser = null;
            state.error = null;
        }
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { fetchOrgEmploymentVerificationUser } = slice.actions;

export function GetOrgEmploymentVerificationUser(token ,name,pageNumber,status) {
    return async (dispatch) => {
        dispatch(slice.actions.fetchOrgEmploymentVerificationUser());
        dispatch(slice.actions.startLoading());
        let response = null;
        try {   
            if (status) {
                response = await axios.get(`${DomainURL.BaseUrl}employment/candidate/list?status=${status}&&name=${name}&pageNumber=${pageNumber}`, {
                    headers: headerParams(token),
                });
            }else{
                response = await axios.get(`${DomainURL.BaseUrl}employment/candidate/list?name=${name}&pageNumber=${pageNumber}`, {
                    headers: headerParams(token),
                });
            }
            dispatch(slice.actions.fetchOrgEmploymentVerificationUser(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function resetOrgUserEmploymentVerification() {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateEmploymentVerificationUser());
    }
}
