import { createSlice } from '@reduxjs/toolkit';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import { headerParams } from 'src/utils/headers';
import axios from '../../../../../utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    loadingInfo: false,
    errorInfo: null,
    empty: false,
    messageInfo: null,
}

const slice = createSlice({
    name: 'OrgExclusiveJobCreate',
    initialState,
    reducers: {
        startLoading(state) {
            state.loadingInfo = true;
            state.messageInfo = null;
        },
        hasError(state, action) {
            state.loadingInfo = false;
            state.errorInfo = action.payload;
        },
        createExclusivJob(state, action) {
            state.loadingInfo = false;
            state.errorInfo = null;
            state.messageInfo = action.payload;
        },
        resetState(state) {
            state.loadingInfo = false;
            state.messageInfo = null;
            state.errorInfo = null
        }
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { createExclusiveJob, resetState } = slice.actions;

export function CreateExclusiveJob(token, payload,) {
    return async (dispatch) => {
        dispatch(slice.actions.resetState());
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`${DomainURL.BaseUrl}job`, { ...payload }, {
                headers: headerParams(token),
            });
            dispatch(slice.actions.createExclusivJob(response?.data?.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error.data));
        }
    };
}

export function resetOrgExclusiveJobCreateState() {
    return async (dispatch) => {
        dispatch(slice.actions.resetState());
    }
}