import { createSlice } from '@reduxjs/toolkit';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import { headerParams } from 'src/utils/headers';
import axios from '../../../../../utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    loading: true,
    error: null,
    cRListCount: null,
}

const slice = createSlice({
    name: 'CrListCount',
    initialState,
    reducers: {
        startLoading(state) {
            state.loading = true;

        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        fetchCrListCount(state, action) {
            state.loading = false;
            state.error = null;
            state.cRListCount = action.payload?.data;

        },
        resetStateCrListCount(state) {
            state.loading = false;
            state.cRListCount = null;
            state.error = null;
        }
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { fetchCrListCount } = slice.actions;

export function GetCrListCount(token, orgId) {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateCrListCount());
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`${DomainURL.BaseUrl}contact/status/count?orgId=${orgId}`, {
                headers: headerParams(token),
            });
            dispatch(slice.actions.fetchCrListCount(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
