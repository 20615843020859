import { createSlice } from '@reduxjs/toolkit';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import { headerParams } from 'src/utils/headers';
import axios from '../../../../../utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    loading: true,
    error: null,
    requirementStatus: null,
}

const slice = createSlice({
    name: 'OrgExclusiveJobRequirementStatus',
    initialState,
    reducers: {
        startLoading(state) {
            state.loading = true;

        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        fetchExclusiveJobRequirement(state, action) {
            state.loading = false;
            state.error = null;
            state.requirementStatus = action.payload?.data;

        },
        resetStateOrgExclusiveJobRequirement(state) {
            state.loading = false;
            state.requirementStatus = null;
            state.error = null;
        }
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { fetchOrgExclusiveJobRequirement } = slice.actions;

export function GetOrgExclusiveJobRequirement(token) {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateOrgExclusiveJobRequirement());
        dispatch(slice.actions.startLoading());

        try {
            const response = await axios.get(`${DomainURL.BaseUrlVersion2}job/requirement-status/categories`, {
                headers: headerParams(token),
            });


            dispatch(slice.actions.fetchExclusiveJobRequirement(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function resetOrgExclusiveJobRequirement() {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateOrgExclusiveJobRequirement());
    }
}


