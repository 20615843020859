// utils
import { paramCase } from 'src/utils/change-case';
import { _id, _postTitles } from 'src/_mock/assets';

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/dashboard',
  JOBSEEKER: '/jobseeker',
  EMPLOYER: '/employer',
  HR: '/hr',
  ONBOARDING: '/onboarding'
};

// ----------------------------------------------------------------------

export const paths = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  docs: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
  zoneUI: 'https://mui.com/store/items/zone-landing-page/',
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  freeUI: 'https://mui.com/store/items/minimal-dashboard-free/',
  figma:
    'https://www.figma.com/file/hjxMnGUJCjY7pX8lQbS7kn/%5BPreview%5D-Minimal-Web.v5.4.0?type=design&node-id=0-1&mode=design&t=2fxnS70DuiTLGzND-0',
  product: {
    root: `/product`,
    checkout: `/product/checkout`,
    details: (id) => `/product/${id}`,
    demo: {
      details: `/product/${MOCK_ID}`,
    },
  },
  post: {
    root: `/post`,
    details: (title) => `/post/${paramCase(title)}`,
    demo: {
      details: `/post/${paramCase(MOCK_TITLE)}`,
    },
  },
  // AUTH
  auth: {
    amplify: {
      login: `${ROOTS.AUTH}/amplify/login`,
      verify: `${ROOTS.AUTH}/amplify/verify`,
      register: `${ROOTS.AUTH}/amplify/register`,
      newPassword: `${ROOTS.AUTH}/amplify/new-password`,
      forgotPassword: `${ROOTS.AUTH}/amplify/forgot-password`,
    },
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
    firebase: {
      login: `${ROOTS.AUTH}/firebase/login`,
      verify: `${ROOTS.AUTH}/firebase/verify`,
      register: `${ROOTS.AUTH}/firebase/register`,
      forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
    },
    auth0: {
      login: `${ROOTS.AUTH}/auth0/login`,
    },
  },
  authDemo: {
    classic: {
      login: `${ROOTS.AUTH_DEMO}/classic/login`,
      register: `${ROOTS.AUTH_DEMO}/classic/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/classic/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/classic/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/classic/verify`,
    },
    modern: {
      login: `${ROOTS.AUTH_DEMO}/modern/login`,
      register: `${ROOTS.AUTH_DEMO}/modern/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/modern/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/modern/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/modern/verify`,
    },
  },

  onboarding: {

    employer: {
      userAccount: `${ROOTS.ONBOARDING}/user-account`,

    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    mail: `${ROOTS.DASHBOARD}/mail`,
    chat: `${ROOTS.DASHBOARD}/chat`,
    blank: `${ROOTS.DASHBOARD}/blank`,
    kanban: `${ROOTS.DASHBOARD}/kanban`,
    calendar: `${ROOTS.DASHBOARD}/calendar`,
    fileManager: `${ROOTS.DASHBOARD}/file-manager`,
    permission: `${ROOTS.DASHBOARD}/permission`,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
      ecommerce: `${ROOTS.DASHBOARD}/ecommerce`,
      analytics: `${ROOTS.DASHBOARD}/analytics`,
      banking: `${ROOTS.DASHBOARD}/banking`,
      booking: `${ROOTS.DASHBOARD}/booking`,
      file: `${ROOTS.DASHBOARD}/file`,
    },
    // common paths
    common: {
      user: {
        edit: `${ROOTS.DASHBOARD}/user/edit`
      },
      notications:{
        view: `${ROOTS.DASHBOARD}/notifications/view-all`
      }
    },
    // jobseekers
    jobseekers: {
      profile: `${ROOTS.JOBSEEKER}/profile`,
      location: {
        // new: `${ROOTS.DASHBOARD}/location/new`,
        edit: `${ROOTS.JOBSEEKER}/location/edit`,
      },
      employment: {
        list: `${ROOTS.JOBSEEKER}/employment/list`,
        verify: (id) => `${ROOTS.JOBSEEKER}/employment/${id}/verify`
      }
      ,
      contactRequest: `${ROOTS.JOBSEEKER}/contact-request/list`,
      experience: {
        new: `${ROOTS.JOBSEEKER}/experience/new`,
        edit: (id) => `${ROOTS.JOBSEEKER}/experience/${id}/edit`,
      },
      education: {
        new: `${ROOTS.JOBSEEKER}/education/new`,
        edit: (id) => `${ROOTS.JOBSEEKER}/education/${id}/edit`,
      },
      certification: {
        new: `${ROOTS.JOBSEEKER}/certification/new`,
        edit: (id) => `${ROOTS.JOBSEEKER}/certification/${id}/edit`,
      },
      jobs: {
        root: `${ROOTS.JOBSEEKER}/jobs`,
        root_myjob: `${ROOTS.JOBSEEKER}/myjobs`,
        details: (id) => `${ROOTS.JOBSEEKER}/jobs/${id}`,
        saved: `${ROOTS.JOBSEEKER}/myjobs/saved`,
        applied: `${ROOTS.JOBSEEKER}/myjobs/applied`,
        exclusive: `${ROOTS.JOBSEEKER}/jobs/exclusive`,
        non_exclusive: `${ROOTS.JOBSEEKER}/jobs/non-exclusive`
      }
    },

    // employer
    employer: {
      companyInfo: {
        view: `${ROOTS.EMPLOYER}/companyInfo/view`,
        edit: `${ROOTS.EMPLOYER}/companyInfo/edit`,
      },
      profile: `${ROOTS.EMPLOYER}/profile`,
      employment: `${ROOTS.EMPLOYER}/employment`,
      contactRequest: `${ROOTS.EMPLOYER}/contact-request`,
      usersList: `${ROOTS.EMPLOYER}/users/list`,
      orgAdmin: {
        user: {
          new: `${ROOTS.EMPLOYER}/users/new`,
          edit: (id) => `${ROOTS.EMPLOYER}/users/${id}/edit`,
        },
        layoffs: `${ROOTS.EMPLOYER}/layoffs/list`,
        employment: {
          list: `${ROOTS.EMPLOYER}/employment/list`,
          edit: (id) => `${ROOTS.EMPLOYER}/employment/${id}/edit`,
        },
        contactRequest: {
          list: `${ROOTS.EMPLOYER}/contact-request/list`,
        },
        jobs: {
          root: `${ROOTS.EMPLOYER}/jobs`,
          exclusive: {
            list: `${ROOTS.EMPLOYER}/jobs/exclusive/list`,
            new: `${ROOTS.EMPLOYER}/jobs/exclusive/new`,
            edit: (id) => `${ROOTS.EMPLOYER}/jobs/exclusive/${id}/edit`,
            view: (id) => `${ROOTS.EMPLOYER}/jobs/exclusive/${id}/view`,
          },
          profile: {
            view: (userId) => `${ROOTS.EMPLOYER}/jobs/exclusive/profile/view/${userId}`,
          }
        },
        discoverTalent: {
          root: `${ROOTS.EMPLOYER}/discover-talent/`,

          favouriteList: `${ROOTS.EMPLOYER}/discover-talent/favouriteList`,
          profile: {
            view: (userId) => `${ROOTS.EMPLOYER}/discover-talent/profile/view/${userId}`,
          },
          discover: {
            searchList: `${ROOTS.EMPLOYER}/discover-talent/discover/searchList`,
          },
        }

      },

    },


    // ========HR===============

    // employer
    hr: {
      companyInfo: {
        view: `${ROOTS.HR}/companyInfo/view`,
      },
      profile: `${ROOTS.HR}/profile`,
      employment: `${ROOTS.HR}/employment`,
      contactRequest: `${ROOTS.HR}/contact-request`,
      usersList: `${ROOTS.HR}/users/list`,
      humanResource: {
        layoffs: `${ROOTS.HR}/layoffs/list`,
        employment: {
          list: `${ROOTS.HR}/employment/list`,
          edit: (id) => `${ROOTS.HR}/employment/${id}/edit`,
        },
        contactRequest: {
          list: `${ROOTS.HR}/contact-request/list`,
        },
        jobs: {
          root: `${ROOTS.HR}/jobs`,
          exclusive: {
            list: `${ROOTS.HR}/jobs/exclusive/list`,
            new: `${ROOTS.HR}/jobs/exclusive/new`,
            edit: (id) => `${ROOTS.HR}/jobs/exclusive/${id}/edit`,
            view: (id) => `${ROOTS.HR}/jobs/exclusive/${id}/view`,
          },
          profile: {
            view: (userId) => `${ROOTS.HR}/jobs/exclusive/profile/view/${userId}`,
          }
        },
        discoverTalent: {
          root: `${ROOTS.HR}/discover-talent/`,

          favouriteList: `${ROOTS.HR}/discover-talent/favouriteList`,
          profile: {
            view: (userId) => `${ROOTS.HR}/discover-talent/profile/view/${userId}`,
          },
          discover: {
            searchList: `${ROOTS.HR}/discover-talent/discover/searchList`,
          },
        }
      },

    },


  },
};
