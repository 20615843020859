import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard, OnboardingGuard, RoleBasedGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { AuthenticatedTemplate } from '@azure/msal-react';


// ************************ ORG ADMIN*************************************

// Company Information
const CompanyInfoEdit = lazy(() => import('src/pages/dashboard/org-admin/company-info/edit'));
const CompanyInfoView = lazy(() => import('src/pages/dashboard/org-admin/company-info/company-info-view'));

//  USERS
const ORGUsersListPage = lazy(() => import('src/pages/dashboard/org-admin/users/users-list'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/org-admin/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/org-admin/user/edit'));

// Layoff
const ORGLayoffListPage = lazy(() => import('src/pages/dashboard/org-admin/layoffs/layoffs-list'));

// Employment Verification

const ORGEmploymentVerificationPage = lazy(() => import('src/pages/dashboard/org-admin/employment-verification/employment-verification-list'));

const ORGEmploymentVerificationFormPage = lazy(() => import('src/pages/dashboard/org-admin/employment-verification/empolyment-verification-form'));

// Contact verification
const ORGContactRequestList = lazy(() => import('src/pages/dashboard/org-admin/contact-request/contact-request-list'));

// Jobs
const ORGExclusiveJobsList = lazy(() => import('src/pages/dashboard/org-admin/jobs/exclusive-jobs-list'));
const ORGExclusiveJobsNewForm = lazy(() => import('src/pages/dashboard/org-admin/jobs/exclusive-jobs-new-form'));
const ORGExclusiveJobsEditForm = lazy(() => import('src/pages/dashboard/org-admin/jobs/exclusive-jobs-edit-form'));
const ORGExclusiveJobsFormDetails = lazy(() => import('src/pages/dashboard/org-admin/jobs/exclusive-jobs-form-details'));
const ORGUserProfileView = lazy(() => import('src/pages/dashboard/org-admin/jobs/profile'));


// Discover Talent
const ORGDiscovertalent = lazy(() => import('src/pages/dashboard/org-admin/discover-talent/discover-talent-view'));
const ORGDTFavouriteListView = lazy(() => import('src/pages/dashboard/org-admin/discover-talent/discover-talent-favourite-list'));


export const orgAdminRoutes = [
    {
        path: 'employer',
        element: (
            <AuthGuard>
                <AuthenticatedTemplate>
                    <RoleBasedGuard hasContent roles={["ORG_ADMIN"]} denied='ORG_ADMIN' sx={{ py: 10 }}>
                        <DashboardLayout>
                            <OnboardingGuard roles={["ORG_ADMIN"]}  >
                                <Suspense fallback={<LoadingScreen />}>
                                    <Outlet />
                                </Suspense>
                            </OnboardingGuard>
                        </DashboardLayout>
                    </RoleBasedGuard>
                </AuthenticatedTemplate>
            </AuthGuard>
        ),

        children: [
            // { path: "employer", element: <IndexPage />, index: true },
            // { path: 'profile', element: <JobseekerProfilePage /> },
            {
                path: 'companyInfo',
                children: [
                    { element: <CompanyInfoView />, index: true },
                    { path: 'view', element: <CompanyInfoView /> },
                    { path: 'edit', element: <CompanyInfoEdit /> },
                ],
            },
            {
                path: 'layoffs',
                children: [
                    { element: <ORGLayoffListPage />, index: true },
                    { path: 'list', element: <ORGLayoffListPage /> },
                ],
            },

            {
                path: 'users',
                children: [
                    { element: <ORGUsersListPage />, index: true },
                    { path: 'list', element: <ORGUsersListPage /> },
                    { path: 'new', element: <UserCreatePage /> },
                    { path: ':id/edit', element: <UserEditPage /> },
                ],
            },

            {
                path: 'contact-request',
                children: [
                    { element: <ORGContactRequestList />, index: true },
                    { path: 'list', element: <ORGContactRequestList /> },
                ]
            },

            {
                path: 'employment',
                children: [
                    { element: <ORGEmploymentVerificationPage />, index: true },
                    { path: 'list', element: <ORGEmploymentVerificationPage /> },
                    { path: ':id/edit', element: <ORGEmploymentVerificationFormPage /> },
                ],
            },

            {
                path: 'jobs',
                children: [
                    { element: <ORGExclusiveJobsList />, index: true },
                    {
                        path: 'exclusive',
                        children: [
                            { element: <ORGExclusiveJobsList />, index: true },
                            { path: 'list', element: <ORGExclusiveJobsList /> },
                            { path: 'new', element: <ORGExclusiveJobsNewForm /> },
                            { path: ':id/edit', element: <ORGExclusiveJobsEditForm /> },
                            { path: ':id/view', element: <ORGExclusiveJobsFormDetails /> },
                            {
                                path: 'profile',
                                children: [
                                    { element: <ORGUserProfileView />, index: true },
                                    { path: 'view/:userId', element: <ORGUserProfileView /> },
                                ]
                            }
                        ]
                    },

                ],
            },
            {
                path: 'discover-talent',
                children: [
                    { element: <ORGDiscovertalent />, index: true },
                    {
                        path: 'discover',
                        children: [
                            { element: <ORGDiscovertalent />, index: true },
                            { path: 'searchList', element: <ORGDiscovertalent /> },


                        ]
                    },
                    { path: 'favouriteList', element: <ORGDTFavouriteListView /> },
                    {
                        path: 'profile',
                        children: [
                            { element: <ORGUserProfileView />, index: true },
                            { path: 'view/:userId', element: <ORGUserProfileView /> },
                        ]
                    },
                ],
            },
        ],
    },
];













