import { createSlice } from '@reduxjs/toolkit';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import { headerParams } from 'src/utils/headers';
import axios from '../../../../../utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    verifyLoading: false,
    errorMsg: null,
    empty: false,
    verifyMsg: null,
}

const slice = createSlice({
    name: 'orgEvUserUpdate',
    initialState,
    reducers: {
        startLoading(state) {
            state.verifyLoading = true;
            state.verifyMsg = null;
        },
        hasError(state, action) {
            state.verifyLoading = false;
            state.errorMsg = action.payload;
        },
        orgEvUserUpdate(state, action) {
            state.verifyLoading = false;
            state.errorMsg = null;
            state.verifyMsg = action.payload;
        },
        resetState(state) {
            state.verifyLoading = false;
            state.verifyMsg = null;
            state.errorMsg = null
        }
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { orgEvUserUpdate, resetState } = slice.actions;

export function UpdateOrgEvUser(token, data) {
    return async (dispatch) => {
        dispatch(slice.actions.resetState());
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`${DomainURL.BaseUrl}employment/verify`, data, {
                headers: headerParams(token),
            });
            dispatch(slice.actions.orgEvUserUpdate(response?.data?.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error.data));
        }
    };
}

export function resetOrgEvUserUpdateState() {
    return async (dispatch) => {
        dispatch(slice.actions.resetState());
    }
}