import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// @msal
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
// react-router-dom
import { useLocation } from 'react-router-dom';
// redux-store
import { useDispatch } from 'src/redux/store';
// slice
import { GetToken } from 'src/redux/slices/common/auth';
import { GetUserDetails } from 'src/redux/slices/common/user-details';
import { loginRequest } from 'src/authConfig';
import { GetUserProfileStatus } from 'src/redux/slices/job-seekers/jobseeker-status';
import { RegisterAlert } from 'src/redux/slices/common/notification-alert';
import { ContactRequestMatricsCount } from 'src/redux/slices/common/contact-request-matrics';
import { EvMatricsCount } from 'src/redux/slices/common/employment-verification-matrics';


export default function AuthGuard({ children }) {
  const dispatch = useDispatch()
  const { instance, inProgress, accounts, } = useMsal();
  const { pathname } = useLocation()
  const [apiData, setApiData] = useState(null);

  useEffect(() => {
    // const accessTokenRequest = {
    //   scopes: ["user.read"],
    //   account: accounts[0],
    // };
    if (!apiData && inProgress === InteractionStatus.None) {
      instance.setActiveAccount(instance.getAllAccounts()[0]);
      instance
        .acquireTokenSilent(loginRequest)
        .then(async (accessTokenResponse) => {
          // Acquire token silent success
          const { idToken } = accessTokenResponse;
          // Call your API with token
          setApiData(idToken);
          dispatch(GetToken(idToken))
          dispatch(GetUserDetails(idToken))
          dispatch(GetUserProfileStatus(idToken))
          // firebase Trigger with token
          dispatch(RegisterAlert(idToken))
          dispatch(ContactRequestMatricsCount(idToken))
          dispatch(EvMatricsCount(idToken))
         
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(loginRequest);
          }
          // console.log(error);
          if (pathname !== "/")
            location.assign('/') // eslint-disable-line
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance, accounts, inProgress, apiData, pathname]);

  // console.log(apiData, inProgress)



  return (<>{children}</>)
}

AuthGuard.propTypes = {
  children: PropTypes.node,
};