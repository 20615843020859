import { createSlice } from '@reduxjs/toolkit';
// header
import { headerParams } from 'src/utils/headers';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    loading: false,
    error: null,
    empty: false,
    filterItems: null,
    staleFilterQuery: {},
    queryData: {},
}

const slice = createSlice({
    name: 'ProfileFilters',
    initialState,
    reducers: {
        startLoading(state, action) {
            state.loading = true;
            state.filterItems = null;
        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload.data;
        },
        profileFilterFetchSuccess(state, action) {
            state.loading = false;
            state.error = null;
            if (action.payload?.data) {
                state.filterItems = action.payload?.data;
                state.empty = false
            } else {
                state.empty = action.payload?.data?.data || true
            }
        },
        setFilerQueries(state, action) {
            state.queryData = action.payload
        },
        resetStateFetch(state) {
            state.loading = false;
            state.filterItems = null;
            state.staleFilterQuery = {};
            state.error = null
            state.empty = false;
        },
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { profileFilterFetchSuccess, setFilerQueries, profileRouter } = slice.actions;

export function getDTFilters(token, data) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`${DomainURL.SearchURLv2}filter/template/user`, { ...data }, { headers: headerParams(token) });
            dispatch(slice.actions.profileFilterFetchSuccess(response.data));
        } catch (error) {   
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function resetJobsFilter() {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateFetch());
    }
}