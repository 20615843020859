import { createSlice } from '@reduxjs/toolkit';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import { headerParams } from 'src/utils/headers';
import axios from '../../../../../utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    loading: true,
    error: null,
    exclusiveJobListCount: null,
}

const slice = createSlice({
    name: 'ExclusiveJobListCount',
    initialState,
    reducers: {
        startLoading(state) {
            state.loading = true;

        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        fetchExclusiveJobListCount(state, action) {
            state.loading = false;
            state.error = null;
            state.exclusiveJobListCount = action.payload?.data;

        },
        resetStateExclusiveJobListCount(state) {
            state.loading = false;
            state.exclusiveJobListCount = null;
            state.error = null;
        }
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { fetchExclusiveJobListCount } = slice.actions;

export function GetExclusiveJobListCount(token, orgId) {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateExclusiveJobListCount());
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`${DomainURL.BaseUrl}job/status/count?orgId=${orgId}`, {
                headers: headerParams(token),
            });
            dispatch(slice.actions.fetchExclusiveJobListCount(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
