import { createSlice } from '@reduxjs/toolkit';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import { headerParams } from 'src/utils/headers';
import axios from '../../../utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    favLoading: true,
    error: null,
    favouriteList: null,
    totalRecord: null
}

const slice = createSlice({
    name: 'orgFavouriteList',
    initialState,
    reducers: {
        startLoading(state) {
            state.favLoading = true;

        },
        hasError(state, action) {
            state.favLoading = false;
            state.error = action.payload;
        },
        getFavouriteList(state, action) {
            state.favLoading = false;
            state.error = null;
            state.favouriteList = action.payload?.data;
            state.totalRecord = action.payload?.metadata?.TotalRecordCount;

        },
        resetStateFetch(state) {
            state.favLoading = false;
            state.favouriteList = null;
            state.error = null;
            state.totalRecord = null;
        }
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { getFavouriteList } = slice.actions;

export function GetFavouriteList(token, pageNumber) {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateFetch());
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`${DomainURL.BaseUrl}favourite/list?pageNumber=${pageNumber}&pageSize=10`, { headers: headerParams(token) });
            dispatch(slice.actions.getFavouriteList(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function resetfavList() {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateFetch());
    }
}