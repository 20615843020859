import { combineReducers } from 'redux';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';
// ============ Common for all kind of users ===============
// B2C token Slice
import authRedcuer from './slices/common/auth';
// ISO Code Slice
import isoRedcuer from './slices/common/iso-code';
// User Details Get Slice
import userDetailRedcuer from './slices/common/user-details';
// User Status Req Get Slice
import updateUserReducer from './slices/common/user-update';
// Common Analytics Get Slice
import generalAnalyticsReducer from './slices/common/analytic';
// Blob auth token get
import blobAuthReducer from './slices/common/blob-auth';
// Upload User Profile Image
import userProfileImgUploadReducers from './slices/common/user-image-upload';
// User mobile OTP Request
import OtpRequestReducers from './slices/common/otp-request'
// SystemRole list Request
import systemRoleListReducers from './slices/employer/system-role-list'
// assigned list Request
import assignedToUsersListReducers from './slices/common/assignedToUsersList'
// Release Reason List Request
import releaseReasonListReducers from './slices/employer/release-reason-list'
// User mobile OTP verification
import OtpVerificationReducers from './slices/common/otp-verify'
// User Logout
import UserLogoutReducers from './slices/common/logout'
// FAQs
import FaqListReducer from './slices/common/faqs-common'
// Notification Alert
import NotificationAlertReducers from './slices/common/notification-alert'
// Notification Alert View All
import NotificationViewAllReducers from './slices/common/notification-view-all'
// users Contact request status check
import CrStatusReducers from './slices/common/contact-request-status'
// initiate Contact request 
import initiateContactRequestReducers from './slices/common/initiate-contact-request'
// users favourite status check
import favouriteCheckReducers from './slices/common/favourite-get'

// Address Search
import FetchAddressSuggestionsReducers from './slices/common/address-search'

import favListReducers from './slices/employer/favourite-list'

import contactRequestMatricsReducers from './slices/common/contact-request-matrics'
import employementVerificationMatricsReducers from './slices/common/employment-verification-matrics'





// initiate favourite 
import favouriteSetReducers from './slices/common/favourite-set'
// Org types list 
import orgTypesListReducers from './slices/employer/org-type-list'
// ============== Jobseekers reducers ==================
// jobseekers Onboard
import jsSignUpReducers from './slices/onboarding/jobseekers/jobseekers-signup'
// Jobseekers Profile Status Get Slice
import jsProfileStatusReducer from './slices/job-seekers/jobseeker-status';
// Jobseekers Profile Details Get Slice
import jsProfileDetailsReducer from './slices/job-seekers/jobseeker-profile';
// Jobseekers Location Preference Get Slice
import jsLocationPreferenceReducer from './slices/job-seekers/location-preference/location-individual';
// Jobseekers Location Preference Put Slice
import jsLocationPreferenceUpdateReducer from './slices/job-seekers/location-preference/location-update';
// Jobseekers Individual Experience Get Slice
import jsIndivExperienceReducer from './slices/job-seekers/experience/experience-individual';
// Jobseekers Website Lookup Get Slice
import jsSiteLookupReducer from './slices/job-seekers/experience/site-lookup';
// Jobseekers Experience Add&Update Put Slice
import jsExperienceNewUpdateReducer from './slices/job-seekers/experience/experience-put';
// Jobseekers Experience Delete Slice
import jsExperienceDeleteReducer from './slices/job-seekers/experience/experience-delete';
// Jobseekers Individual Education Get Slice
import jsIndivEducationReducer from './slices/job-seekers/education/education-individual';
// Jobseekers Education Categories Get Slice
import jsEducationCategoriesReducer from './slices/job-seekers/education/education-categories';
// Jobseekers Education Add&Update Put Slice
import jsEducationNewUpdateReducer from './slices/job-seekers/education/education-put';
// Jobseekers Education Delete Slice
import jsEducationDeleteReducer from './slices/job-seekers/education/education-delete';
// Jobseekers Individual Certification Get Slice
import jsIndivCertificationReducer from './slices/job-seekers/certification/certification-individual';
// Jobseekers Certification Add&Update Put Slice
import jsCertificationNewUpdateReducer from './slices/job-seekers/certification/certification-put';
// Jobseekers Certification Delete Slice
import jsCertificationDeleteReducer from './slices/job-seekers/certification/certification-delete';
// Jobseekers Employment List Get Slice
import jsEmploymentListReducer from './slices/job-seekers/employment/employment-list';
import jsEvInitReducer from './slices/job-seekers/employment/initiate-ev';
import jsEvAcceptReducer from './slices/job-seekers/employment/accept-ev';
import jsEvIndiDetailsReducer from './slices/job-seekers/employment/view-ev-details';
// Jobseekers Contact Request List Get Slice
import jsContactReqListReducer from './slices/job-seekers/contact-request/get-contact-request';
import jsContactReqResponceReducer from './slices/job-seekers/contact-request/put-contact-request';
// Jobseekers External Job List Get Slice
import jsExtJobsListReducer from './slices/job-seekers/jobs/external/jobs-list';
import jsExtJobsPreviewReducer from './slices/job-seekers/jobs/external/jobs-individual';

// Jobseeker Internal Jobs Slice
import jsInternalJobsReducers from './slices/job-seekers/jobs/internal/jobs-list'
import jsInternalJobsFilters from './slices/job-seekers/jobs/internal/jobs-filters'
import jsInternalJobsPreviewReducer from './slices/job-seekers/jobs/internal/jobs-individual'
import jsInternalJobsFavouriteReducer from './slices/job-seekers/jobs/internal/jobs-favourite'
import jsInternalJobsFavDetailsReducer from './slices/job-seekers/jobs/internal/jobs-favourite-details'
import jsInternalJobsApplyReducer from './slices/job-seekers/jobs/internal/jobs-apply'

// ================================= employer reducers====================
// Org admin comapany info get
import OrgCompanyInfoGetReducer from './slices/employer/org-admin/company-info/company-info-get';
// Org admin comapany info put
import OrgCompanyInfoPutReducer from './slices/employer/org-admin/company-info/company-info-update';

import orgProfileImgUploadReducers from './slices/employer/org-admin/company-info/company-image-upload';

import OrgUsersListReducer from './slices/common/org-admin/users-list/users-list';
// Org admin Users List Count Get Slice 
import OrgUsersListCountReducer from './slices/employer/org-admin/user/user-count';
// Org admin User Get Slice 
import fetchOrgIndiUserReducer from './slices/employer/org-admin/user/user-get';
// Org admin User Put Slice
import putOrgIndiUserReducer from './slices/employer/org-admin/user/user-update';
// Org admin User create Slice
import createOrgIndiUserReducer from './slices/employer/org-admin/user/user-create';
// Org admin User search Slice
import searchOrgIndiUserReducer from './slices/employer/org-admin/user/user-search';
// Org admin Layoff List Slice
import OrgLayoffListReducer from './slices/employer/org-admin/layoffs/layoff-list';
// Org admin Layoff Search Slice
import OrgLayoffSearchReducer from './slices/employer/org-admin/layoffs/layoffs-search';
// Org admin Layoff Search Slice
import orgLayoffsUploadReducer from './slices/employer/org-admin/layoffs/layoff-upload';
// Org admin EmploymentVerification List Slice
import OrgEmploymentVerificationListReducer from './slices/employer/org-admin/employmentVerification/employment-verification-list';
// Org admin EmploymentVerification search User Slice
import OrgEmploymentVerificationSearchUserReducer from './slices/employer/org-admin/employmentVerification/employment-verification-search'
// org admin EmploymentVerification get user details
import OrgEvUserDetailsReducer from './slices/employer/org-admin/employmentVerification/get-employement-verification';
// org admin EmploymentVerification update user details
import OrgEvUserDetailsUpdatesReducer from './slices/employer/org-admin/employmentVerification/verify-employment-verification';
// org admin EmploymentVerification list count
import OrgEVListCountReducers from './slices/employer/org-admin/employmentVerification/employment-verification-list-count';
// org admin contact request list
import OrgContactRequestListReducers from './slices/employer/org-admin/contact-request/contact-request-list';
// org admin contact request list count
import OrgCrListCountReducers from './slices/employer/org-admin/contact-request/contact-request-list-count';
// org admin Jobs requirement status
import OrgExclusiveJobRequirementStatusReducers from './slices/employer/org-admin/exclusive-jobs/exclusive-job-requirement-status';
// org admin Exclusive Jobs List
import OrgExclusiveJobsListReducers from './slices/employer/org-admin/exclusive-jobs/exclusive-jobs-list';
// org admin Exclusive Jobs List count
import OrgExclusiveJobsListCountReducers from './slices/employer/org-admin/exclusive-jobs/exclusive-jobs-list-count';
// org admin Exclusive Jobs search
import OrgExclusiveIndiJobReducers from './slices/employer/org-admin/exclusive-jobs/exclusive-job-get';
// org admin Exclusive create job
import OrgExclusiveCreateJobReducers from './slices/employer/org-admin/exclusive-jobs/exclusive-job-create';
// org admin Exclusive Jobs pipeline
import OrgExclusiveJobPipelineReducers from './slices/employer/org-admin/exclusive-jobs/exclusive-job-pipeline';

import OrgExclusiveJobPipelineCountReducers from './slices/employer/org-admin/exclusive-jobs/exclusive-job-pipeline-count';

import layoffInviteSendReducers from './slices/employer/org-admin/layoffs/layoff-user-invite';





// DISCOVER TALENT
import DTProfileListReducers from './slices/employer/org-admin/discover-talent/DT-profile-list';
import DTProfileIndividualReducers from './slices/employer/org-admin/discover-talent/DT-profile-individual';
import DTProfileFilterReducers from './slices/employer/org-admin/discover-talent/DT-profile-filter';
import InappPatch from './slices/common/inapp-patch';


// ----------------------------------------------------------------------
export const createNoopStorage = () => ({
  getItem() {
    return Promise.resolve(null);
  },
  setItem(_key, value) {
    return Promise.resolve(value);
  },
  removeItem() {
    return Promise.resolve();
  },
});
export const storage =
  typeof window !== 'undefined' ? createWebStorage('local') : createNoopStorage();
export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};
const rootReducer = combineReducers({
  // ============ Common for all kind of user users ===============
  auth: authRedcuer, // -> For B2C token
  iso: isoRedcuer, // -> For ISO Codes
  jsSignup: jsSignUpReducers, // -> For Jobseeker Signup
  userInfo: userDetailRedcuer, // -> For User Details
  userUpdate: updateUserReducer, // -> For Update User Details
  generalAnalytics: generalAnalyticsReducer, // -> For blobAuth Details
  location: FetchAddressSuggestionsReducers, // -> For Update User Details
  userBlob: blobAuthReducer, // -> For blobAuth Details
  userImgUpload: userProfileImgUploadReducers, // -> For blobAuth Details
  userMobOTPVerify: OtpVerificationReducers, // -> For User Mobile OTP
  userMobOTPReq: OtpRequestReducers, // -> For User Request OTP to Mobile Verification
  userLogout: UserLogoutReducers, // -> For User Logout
  userFaqList: FaqListReducer, // -> For FQAs
  notificationAlert: NotificationAlertReducers,
  notificationViewAll: NotificationViewAllReducers,
  isfavourite: favouriteCheckReducers,
  isSetfavourite: favouriteSetReducers,
  initiateCrStatus: initiateContactRequestReducers,
  favList: favListReducers,
  inappPatch: InappPatch,
  crMatrics: contactRequestMatricsReducers,
  evMatrics: employementVerificationMatricsReducers,


  systemRoleList: systemRoleListReducers, // -> For system role list
  assignedToList: assignedToUsersListReducers, // -> For system role list
  releaseReasonList: releaseReasonListReducers, // ->  For release reason  list
  OrgTypesList: orgTypesListReducers, // ->  For org types list
  // ============== Jobseekers reducers ==================
  profileStatus: jsProfileStatusReducer, // -> For User Profile Status
  jsProfile: jsProfileDetailsReducer, // -> For Jobseeker Profile Details
  jsLocation: jsLocationPreferenceReducer, // -> For Jobseeker Get Location Preference
  jsLocationUpdate: jsLocationPreferenceUpdateReducer, // -> For Jobseeker Location Preference Update
  jsIndivExp: jsIndivExperienceReducer, // -> For Jobseekers Individual Experience Get
  jsSiteLookup: jsSiteLookupReducer, // -> For Jobseekers Site Lookup Get
  jsPutExperience: jsExperienceNewUpdateReducer, // -> For Jobseekers Experience Add & Update Put
  jsDelExperience: jsExperienceDeleteReducer, // -> For Jobseekers Experience Delete
  jsIndivEdu: jsIndivEducationReducer, // -> For Jobseekers Individual Education Get
  jsEduCategories: jsEducationCategoriesReducer, // -> For Jobseekers Education Categories Get
  jsPutEducation: jsEducationNewUpdateReducer,// -> For Jobseekers Education Add & Update Put
  jsDelEducation: jsEducationDeleteReducer,// -> For Jobseekers Education Delete
  jsIndivCert: jsIndivCertificationReducer, // -> For Jobseekers Individual Certification Get
  jsPutCertification: jsCertificationNewUpdateReducer,// -> For Jobseekers Certification Add & Update Put
  jsDelCertification: jsCertificationDeleteReducer,// -> For Jobseekers Certification Delete
  jsEvList: jsEmploymentListReducer, // -> For Jobseeker Employment List
  jsInitEv: jsEvInitReducer, // -> For Jobseeker Employment List
  jsAcceptEv: jsEvAcceptReducer, // -> For Jobseeker Employment List
  jsIndiEv: jsEvIndiDetailsReducer, // -> For Jobseeker Employment List
  jsCrList: jsContactReqListReducer, // -> For Jobseeker Contact Request List
  jsCrtReqResponce: jsContactReqResponceReducer, // -> For Jobseeker Contact Request Responce


  jsIntrJobList: jsInternalJobsReducers, // -> For Jobseeker Internal Job List
  jsIntrJobFilters: jsInternalJobsFilters, // -> For Jobseeker Internal Job Filters
  jsIntrJobPreview: jsInternalJobsPreviewReducer, // -> For Jobseeker Internal Job Preview
  jsIntrJobFav: jsInternalJobsFavouriteReducer, // -> For Jobseeker Internal Job Favourite
  jsIntrJobFavDetails: jsInternalJobsFavDetailsReducer, // -> For Jobseeker Internal Job Fav Details
  jsIntrJobApply: jsInternalJobsApplyReducer, // -> For Jobseeker Internal Job Apply

  jsExtJobList: jsExtJobsListReducer, // -> For Jobseeker External Job List
  jsExtJobPreview: jsExtJobsPreviewReducer, // -> For Jobseeker External Job Preview

  // ===============Employer reducers=================================
  orgCompanyInfo: OrgCompanyInfoGetReducer,
  orgCompanyInfoUpdate: OrgCompanyInfoPutReducer,
  orgImgUpload: orgProfileImgUploadReducers, // -> For blobAuth Details
  OrgUsersList: OrgUsersListReducer, // -> For Org admin users List           
  OrgUsersListCount: OrgUsersListCountReducer, // -> For Org admin users List count           
  orgIndiUser: fetchOrgIndiUserReducer, // -> For Org admin individual user Request
  putOrgIndiUser: putOrgIndiUserReducer, // -> For Org admin individual user update
  createOrgIndiUser: createOrgIndiUserReducer, // -> For Org admin individual user create
  searchOrgIndiUser: searchOrgIndiUserReducer, // -> For Org admin individual user search
  orgLayoffsList: OrgLayoffListReducer, // -> For Org admin layoff list
  orgLayoffsSearch: OrgLayoffSearchReducer, // -> For Org admin layoff search
  orgLayoffsUpload: orgLayoffsUploadReducer,// -> For Org admin layoff upload
  OrgEmploymentVerificationList: OrgEmploymentVerificationListReducer,// -> For Org admin employment verification list 
  OrgEvListCount: OrgEVListCountReducers,// -> For Org admin employment verification list 
  OrgEmploymentVerificationSearchList: OrgEmploymentVerificationSearchUserReducer,// -> For Org admin employment verification search user 
  orgEvUserDetails: OrgEvUserDetailsReducer,// -> For Org admin employment verification user deatails get
  orgEvUserUpdate: OrgEvUserDetailsUpdatesReducer,// -> For Org admin employment verification user deatails get
  orgContactRequestList: OrgContactRequestListReducers,// -> For Org admin contact request list 
  OrgCrListCount: OrgCrListCountReducers,// -> For Org admin contact request list count
  OrgExclusiveJobRequirementStatus: OrgExclusiveJobRequirementStatusReducers,// -> For Org admin exclusive jobs requirement status
  OrgExclusiveJobsList: OrgExclusiveJobsListReducers,// -> For Org admin exclusive jobs list
  OrgExclusiveJobsListCount: OrgExclusiveJobsListCountReducers,// -> For Org admin exclusive jobs list count
  OrgExclusiveIndiJob: OrgExclusiveIndiJobReducers,// -> For Org admin exclusive jobs get
  OrgExclusiveCreateJob: OrgExclusiveCreateJobReducers,// -> For Org admin exclusive jobs create
  orgExclusiveJobPipeline: OrgExclusiveJobPipelineReducers, // -> For Org Exclusive Jobs pipeline
  orgExclusivePipelineCount: OrgExclusiveJobPipelineCountReducers, // -> For Org Exclusive Jobs pipeline count
  orgInviteSend: layoffInviteSendReducers, // -> For Org user invite send
  crStatus: CrStatusReducers, // -> For Org Exclusive Jobs pipeline
  DTProfileList: DTProfileListReducers,// -> For DT search list
  DTProfileIndividual: DTProfileIndividualReducers,// -> For DT search Individual Profile
  DTProfileFilter: DTProfileFilterReducers // -> For DT search Individual Profile
});
export default rootReducer;
