import { createSlice } from '@reduxjs/toolkit';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import { headerParams } from 'src/utils/headers';
import axios from '../../../utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    Loading: false,
    error: null,
    message: null,
}

const slice = createSlice({
    name: 'initiateCRStatus',
    initialState,
    reducers: {
        startLoading(state, action) {
            state.Loading = true;
            state.message = null;
        },
        hasError(state, action) {
            state.Loading = false;
            state.error = action.payload.data;
        },
        initiateCRStatus(state, action) {
            state.Loading = false;
            state.error = null;
            state.message = action.payload;
        },
        resetStateCrState(state) {
            state.Loading = false;
            state.message = null;
            state.error = null
        }
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { initiateCRStatus } = slice.actions;

export function initiateContactRequest(token, userId) {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateCrState());
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`${DomainURL.BaseUrl}contact/candidate/${userId}`, {}, {
                headers: headerParams(token),
            });
            dispatch(slice.actions.initiateCRStatus(response?.data?.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function resetinitiateCrState() {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateCrState());
    }
}