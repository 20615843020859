import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
// i18n
import 'src/locales/i18n';

// scrollbar
import 'simplebar-react/dist/simplebar.min.css';

// lightbox
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

// routes
import Router from 'src/routes/sections';
// theme
import ThemeProvider from 'src/theme';
// locales
import { LocalizationProvider } from 'src/locales';
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// components
import ProgressBar from 'src/components/progress-bar';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';
import { SettingsProvider, SettingsDrawer } from 'src/components/settings';
// sections
// auth
import { MsalProvider } from '@azure/msal-react';
// redux
import { useDispatch, useSelector } from 'src/redux/store';
// slice
import { GetIso } from './redux/slices/common/iso-code';
import { requestFcmToken, } from './components/notifications/push-notification';

// env

// import { AuthProvider, AuthConsumer } from 'src/auth/context/auth0';
// import { AuthProvider, AuthConsumer } from 'src/auth/context/amplify';
// import { AuthProvider, AuthConsumer } from 'src/auth/context/firebase';

// ----------------------------------------------------------------------

export default function App({ instance }) {
  // eslint-disable-next-line no-bitwise

  const fetchFcmToken = async () => {
    try {
      await requestFcmToken();
    } catch (error) {
      console.error(error)
    }
  }

  const fcmInitialize = useCallback(async () => {
    fetchFcmToken();
  }, []);

  useEffect(() => {
    fcmInitialize();
  }, [fcmInitialize]);

  const dispatch = useDispatch()
  const { isoList } = useSelector(state => state.iso)
  useScrollToTop();

  useEffect(() => {
    if (!isoList) {
      dispatch(GetIso())
    }
  }, [dispatch, isoList])

  return (

    <MsalProvider instance={instance}>

      <LocalizationProvider>
        <SettingsProvider
          defaultSettings={{
            themeMode: 'light', // 'light' | 'dark'
            themeDirection: 'ltr', //  'rtl' | 'ltr'
            themeContrast: 'default', // 'default' | 'bold'
            themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
            themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            themeStretch: false,
          }}
        >
          <ThemeProvider>
            <MotionLazy>
              <SnackbarProvider>
                <SettingsDrawer />
                <ProgressBar />
                <Router />
              </SnackbarProvider>
            </MotionLazy>
          </ThemeProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </MsalProvider>
  );
}

App.propTypes = {
  instance: PropTypes.any,
};