import { createSlice } from '@reduxjs/toolkit';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import { headerParams } from 'src/utils/headers';
import axios from '../../../../../utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    loading: false,
    error: null,
    empty: false,
    profileList: null,
    metadata: null,
    queryHolder: null,
    stalePage: null,
    initialQuery: null,
}

const slice = createSlice({
    name: 'DTProfileList',
    initialState,
    reducers: {
        startLoading(state) {
            state.loading = true;
            state.profileList = null;

        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        DTFetchSuccess(state, action) {
            state.loading = false;
            state.error = null;
            if (action.payload?.data?.data) {
                state.profileList = action.payload?.data?.data;
                state.metadata = action.payload?.data?.metadata;
                state.queryHolder = action.payload?.queryHolder;
                state.stalePage = action.payload?.stalePage;
                state.empty = false
            } else {
                state.empty = action.payload?.data?.data || true
                state.queryHolder = action.payload?.queryHolder;
            }
        }, intialSearch(state, action) {
            state.initialQuery = action.payload
        },
        resetStateFetch(state) {
            state.loading = false;
            state.profileList = null;
            state.metadata = null;
            state.error = null
            state.queryHolder = null;
            state.empty = false;
        },
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { DTFetchSuccess, intialSearch } = slice.actions;

export function getDTProfileList(token, data, stalePage) {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateFetch());
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`${DomainURL.UserSearchURLV2}query/user`, { ...data }, {
                headers: headerParams(token)
            });

            dispatch(slice.actions.DTFetchSuccess({ data: response.data, queryHolder: { ...data }, stalePage }));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function resetDTProfileListState() {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateFetch());
    }
}
