import { createSlice } from '@reduxjs/toolkit';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import { headerParams } from 'src/utils/headers';
import axios from '../../../../../utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    inviteLoading: false,
    inviteError: null,
    inviteMsg: null,
}

const slice = createSlice({
    name: 'OrgLayoffinvite',
    initialState,
    reducers: {
        startinviteLoading(state) {
            state.inviteLoading = true;

        },
        hasinviteError(state, action) {
            state.inviteLoading = false;
            state.inviteError = action.payload;
        },
        fetchOrgLayoffInvite(state, action) {
            state.inviteLoading = false;
            state.inviteError = null;
            state.inviteMsg = action.payload?.data;

        },
        resetStateLayoffInvite(state) {
            state.inviteLoading = false;
            state.inviteMsg = null;
            state.inviteError = null;
        }
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { fetchOrgLayoffInvite } = slice.actions;

export function inviteSend(token, email) {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateLayoffInvite());
        dispatch(slice.actions.startinviteLoading());
        try {
            const response = await axios.patch(`${DomainURL.BaseUrl}user/release/invite/${email}`, '', {
                headers: headerParams(token),
            });
            dispatch(slice.actions.fetchOrgLayoffInvite(response.data));
        } catch (inviteError) {
            dispatch(slice.actions.hasinviteError(inviteError));
        }
    };
}

export function resetStateLayoffInviteState() {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateLayoffInvite());
    }
}
