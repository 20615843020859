import { createSlice } from '@reduxjs/toolkit';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import { headerParams } from 'src/utils/headers';
import axios from '../../../../../utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    loading: true,
    error: null,
    usersList: null,
    totalpage: null,
}

const slice = createSlice({
    name: 'OrgUsersList',
    initialState,
    reducers: {
        startLoading(state) {
            state.loading = true;
            state.usersList = null;
            state.totalpage = null;
        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        getusersList(state, action) {
            state.loading = false;
            state.error = null;
            state.usersList = action.payload?.data;
            state.totalpage = action.payload?.metadata?.TotalRecordCount;
        },
        resetStateFetch(state) {
            state.loading = true;
            state.usersList = null;
            state.totalpage = null;
            state.error = null;
        }
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { getusersList } = slice.actions;

export function GetOrgUsersList(token, orgId, pageNumber, pageSize, role) {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateFetch());
        dispatch(slice.actions.startLoading());
        let response = null
        try {
            if (role) {
                response = await axios.get(`${DomainURL.BaseUrlVersion2}user/list?orgId=${orgId}&systemRole=${role}&pageNumber=${pageNumber}&pageSize=${pageSize}`, {
                    headers: headerParams(token),
                });
            } else {
                response = await axios.get(`${DomainURL.BaseUrlVersion2}user/list?orgId=${orgId}&pageNumber=${pageNumber}&pageSize=${pageSize}`, {
                    headers: headerParams(token),
                });
            }
            dispatch(slice.actions.getusersList(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function resetOrgUserListState() {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateFetch());
    }

}
