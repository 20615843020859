import { createSlice } from '@reduxjs/toolkit';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import { headerParams } from 'src/utils/headers';
import axios from '../../../../../utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    companyInfoLoading: false,
    error: null,
    successMsg: null,
}

const slice = createSlice({
    name: 'CompanyInfoUpdate',
    initialState,
    reducers: {
        startLoading(state) {
            state.companyInfoLoading = true;

        },
        hasError(state, action) {
            state.companyInfoLoading = false;
            state.error = action.payload.data;
        },
        updateCompanyInfo(state, action) {
            state.companyInfoLoading = false;
            state.error = null;
            state.successMsg = action.payload?.data;

        },
        resetStateCompanyInfo(state) {
            state.companyInfoLoading = false;
            state.successMsg = null;
            state.error = null;
        }
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { updateCompanyInfo } = slice.actions;

export function UpdateOrgProfile(token, data,orgId) {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateCompanyInfo());
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`${DomainURL.BaseUrl}organization/${orgId}`, { ...data }, {
                headers: headerParams(token),
            });
            dispatch(slice.actions.updateCompanyInfo(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function resetCompanyInfo() {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateCompanyInfo());
    }
}


