import { createSlice } from '@reduxjs/toolkit';
// config
import { DomainURL } from 'src/DomainURL';
// header
import { headerParams } from 'src/utils/headers';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    loading: false,
    error: null,
    empty: false,
    profilePreview: null,
}

const slice = createSlice({
    name: 'individualIntProfile',
    initialState,
    reducers: {
        startLoading(state, action) {
            state.loading = true;
            state.profilePreview = null;
        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload.data;
        },
        profilePrviewSuccess(state, action) {
            state.loading = false;
            state.error = null;
            state.profilePreview = action.payload;
        },
        resetStateFetch(state, action) {
            state.loading = false;
            state.profilePreview = null;
            state.error = null
        }
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { profilePrviewSuccess, } = slice.actions;

export function getDTIndividualProfile(token, profileId) {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateFetch());
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`${DomainURL.BaseUrl}user/candidate/${profileId}`, { headers: headerParams(token) });
            dispatch(slice.actions.profilePrviewSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function resetIndividualProfile() {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateFetch());
    }
}