import { format, getTime, formatDistanceToNow, intervalToDuration, addDays } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy';
  const utcDate = utcToZonedTime(new Date(date), 'Etc/UTC');
  return date ? format(utcDate, fm) : '';
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fDateLenghtofMonth(date) {
  const utcDate = utcToZonedTime(new Date(date), 'Etc/UTC');
  return format(utcDate, ' MMM yyyy');
}

// Date(Text form) Time Prifix
export function CustomfDateTimeSuffix(date) {
  const utcDate = utcToZonedTime(new Date(date), 'Etc/UTC');
  return format(utcDate, 'dd MMM yyyy  p');
}

export function fTimestamp(date) {
  const utcDate = utcToZonedTime(new Date(date), 'Etc/UTC');
  return date ? getTime(utcDate) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
      addSuffix: true,
    })
    : '';
}

// =================== Custom Modification ===============================


// Date and Time
export function CustomfDateTime(date) {
  const utcDate = utcToZonedTime(new Date(date), 'Etc/UTC');
  return format(utcDate, 'dd MMM yyyy HH:mm');
}


// for find distace between two dates
export function fdistance(startDate, endDate) {
  const { years, months } = intervalToDuration({ start: Date.parse(utcToZonedTime(new Date(startDate), 'Etc/UTC')), end: Date.parse(endDate) !== null ? addDays(Date.parse(utcToZonedTime(new Date(endDate), 'Etc/UTC')), 1) : new Date() });
  return `${[years]} yr ${[months]} mos`
}
// for month & Year
export function fMonthYear(date) {
  const utcDate = utcToZonedTime(new Date(date), 'Etc/UTC');
  return format(utcDate, ' MMM yyyy');
}

