import { createSlice } from '@reduxjs/toolkit';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import { headerParams } from 'src/utils/headers';
import axios from '../../../../../utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    loading: true,
    error: null,
    contactRequestList: null,
}

const slice = createSlice({
    name: 'OrgContactRequestList',
    initialState,
    reducers: {
        startLoading(state) {
            state.loading = true;

        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        fetchOrgContactRequestList(state, action) {
            state.loading = false;
            state.error = null;
            state.contactRequestList = action.payload?.data;

        },
        resetStateOrgContactRequestList(state) {
            state.loading = false;
            state.contactRequestList = null;
            state.error = null;
        }
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { fetchOrgContactRequestList } = slice.actions;

export function GetOrgContactRequestList(token, pageNumber, status) {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateOrgContactRequestList());
        dispatch(slice.actions.startLoading());
        let response = null

        try {
            if (status) {
                response = await axios.get(`${DomainURL.BaseUrl}contact/filter?status=${status}&pageNumber=${pageNumber}&&pageSize=10`, {
                    headers: headerParams(token),
                });
            } else {
                response = await axios.get(`${DomainURL.BaseUrl}contact/filter?pageNumber=${pageNumber}&pageSize=10`, {
                    headers: headerParams(token),
                });
            }

            dispatch(slice.actions.fetchOrgContactRequestList(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function resetOrgContactRequestList() {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateOrgContactRequestList());
    }
}


