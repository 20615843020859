import { createSlice } from '@reduxjs/toolkit';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import { headerParams } from 'src/utils/headers';
import axios from '../../../../../utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    loadingInfo: false,
    error: null,
    layoffUser: null,
}

const slice = createSlice({
    name: 'OrgLayoffSearch',
    initialState,
    reducers: {
        startLoading(state) {
            state.loadingInfo = true;

        },
        hasError(state, action) {
            state.loadingInfo = false;
            state.error = action.payload;
        },
        fetchOrgLayoffUser(state, action) {
            state.loadingInfo = false;
            state.error = null;
            state.layoffUser = action.payload?.data;

        },
        resetStateLayoffUser(state) {
            state.loadingInfo = false;
            state.layoffUser = null;
            state.error = null;
        }
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { fetchOrgLayoffUser } = slice.actions;

export function GetOrgLayoffUser(token, orgId, email, pageNumber) {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateLayoffUser());
        dispatch(slice.actions.startLoading());
        let response = null;
        try {   
            if (email && orgId) {
                response = await axios.get(`${DomainURL.BaseUrl}user/release/invite-list?orgId=${orgId}&email=${email}&pageNumber=${pageNumber}&pageSize=10`, {
                    headers: headerParams(token),
                });
            } else if (orgId) {
                response = await axios.get(`${DomainURL.BaseUrl}user/release/invite-list?orgId=${orgId}`, {
                    headers: headerParams(token),
                });
            }

            dispatch(slice.actions.fetchOrgLayoffUser(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
